<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="unbindApply">
		<el-tabs v-model="currentTab" @tab-click="changeTab">
			<el-tab-pane label="待审核" name="0"></el-tab-pane>
			<el-tab-pane label="已通过" name="1"></el-tab-pane>
			<el-tab-pane label="已拒绝" name="2"></el-tab-pane>
		</el-tabs>
		<div class="unbindApply-content">
			<div class="unbindApply-search">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">学号</span>
						<el-input class="common-row-ipt" placeholder="请输入学号" v-model="searchForm.studentId" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">姓名</span>
						<el-input class="common-row-ipt" placeholder="请输入姓名" v-model="searchForm.name" />
					</div>
					<div class="common-row-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
				</div>
			</div>
			<div class="unbindApply-table">
				<el-table :data="tableData" border>
					<el-table-column prop="regNum" label="注册号">
					</el-table-column>
					<el-table-column prop="volunteerName" label="姓名">
					</el-table-column>
					<el-table-column prop="gender" label="性别">
						<template slot-scope="scope">
							<span>{{scope.row.gender == true?"男":scope.row.gender == false?"女":"-"}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="collegeName" label="学院班级">
						<template slot-scope="scope">
							<span>{{scope.row.collegeName}}{{scope.row.faculty}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="portrait" label="一寸白照片">
						<template slot-scope="scope">
							<el-image fit="cover" class="action-portrait" :src="scope.row.portrait"
								:preview-src-list="[scope.row.portrait]">
							</el-image>
						</template>
					</el-table-column>
					<el-table-column prop="gender" label="审核状态">
						<template slot-scope="scope">
							<span>{{scope.row.status == 0?"待审核":scope.row.status ==1?"已通过":scope.row.status == 2?"已拒绝":""}}</span>
						</template>
					</el-table-column>
					<el-table-column v-if="currentTab == 0" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('agree', scope)">通过</span>
								<el-divider direction="vertical"></el-divider>
								<span @click="actionFn('refeuse', scope)">拒绝</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<refuse-reason :isOpen="dialogVisible" @cancle="closeDialog" @submit="submitDialog" />
	</div>
</template>

<script>
	import refuseReason from "./components/refuseReason.vue";
	import {
		listVolunteerPhoto,
		verifyVolunteerPhoto,
	} from "@/api/student";
	export default {
		components: {
			refuseReason
		},
		data() {
			return {
				currentTab: "0",
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				searchForm: {},
				dialogVisible: false,
				verifyId: "",
				volunteerId: ""
			};
		},
		mounted() {
			this.getVolunteerPhotoList();
		},
		methods: {
			changeTab(e) {
				this.currentTab = e.name
				this.pageIndex = 1;
				this.tableData = [];
				this.getVolunteerPhotoList();
			},
			actionFn(type, scope) {
				this.verifyId = scope.row.id
				this.volunteerId = scope.row.volunteerId
				switch (type) {
					case "agree":
						this.$confirm('确定通过此志愿者的证照片审核吗?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'error',
							iconClass: "message-icon"
						}).then(() => {
							verifyVolunteerPhoto(scope.row.id, {
								status: 1,
								volunteerId: scope.row.volunteerId
							}).then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: '志愿者的证照片审核吗已通过!'
									});
									this.pageIndex = 1;
									this.tableData = [];
									this.getVolunteerPhotoList();
								}
							})
						}).catch(() => {});
						break;
					case "refeuse":
						this.dialogVisible = true
						break;
				}
			},
			getVolunteerPhotoList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					status: this.currentTab
				}
				if (this.searchForm.studentId) {
					this.$set(params, "studentId", this.searchForm.studentId)
				}
				if (this.searchForm.name) {
					this.$set(params, "name", this.searchForm.name)
				}
				listVolunteerPhoto(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getVolunteerPhotoList();
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.getVolunteerPhotoList();
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.getVolunteerPhotoList();
			},
			closeDialog() {
				this.dialogVisible = false
			},
			submitDialog(props) {
				let params = {
					volunteerId: this.volunteerId,
					reson: props,
					status: 2
				}
				console.log("submitDialog", params)
				verifyVolunteerPhoto(this.verifyId, params).then((res) => {
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '志愿者的证照片审核吗已拒接!'
						});
						this.pageIndex = 1;
						this.tableData = [];
						this.dialogVisible = false
						this.getVolunteerPhotoList();
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}
	
	.message-icon {
		display: none;
	}

	.action-portrait {
		width: auto;
		height: 56px;
	}

	.unbindApply {
		.unbindApply-content {
			padding: 0 20px 20px 20px;

			.unbindApply-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.unbindApply-table {
				margin-top: 20px;
			}
		}
	}
</style>
